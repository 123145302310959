<template>
  <page title="结算商品">
    <div class=" bg-white flex px-4 items-center h-11" slot="fixed-top">
      <div >共<b class="text-red-400 mx-1">5</b>件商品</div>
    </div>
    <list :empty="!TestData.examines.length">
      <media-item 
        :image="item.image" 
        :title="item.title"  
        img-ratio="16/15"
        img-width="90"
        note="有了它你再也不用愁了"
        class="flex-1"
        v-for="item in TestData.examines" 
        :key="item.id" 
      >
        <div class="flex flex-wrap mt-2">
          <tag color="green" class="mr-2">红色</tag>
          <tag color="red" class="mr-2">125G</tag>
          <tag color="blue" class="mr-2">全网通</tag>
        </div>
        <div class="flex items-center justify-between mt-2" >
          <div class="text-red-600 font-medium"><span class="text-xs">¥</span>89.9</div>
          <div class="flex items-center">
            <i class="iconfont icon-add-select"></i>
            <div class="h-6 w-10 rounded leading-6 text-sm bg-gray-200 text-center mx-2">10</div>
            <i class="iconfont icon-sami-select"></i>
          </div>
        </div>
      </media-item>
    </list>
    <btn  size="lg" block text-size="base" radius="none" @click="$router.back(-1)" slot="footer">去结算</btn>


  </page>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  data(){
    return{
      
    }
  },
  computed: {
    ...mapGetters([
      'TestData'
    ])
  },
  methods:{},
  created(){}
}
</script>

<style lang='stylus'>

</style>